<script setup>

import {ChevronRightIcon} from "@heroicons/vue/24/outline/index.js";
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import CIcon from "@coreui/icons-vue";

const props = defineProps({
  navigation: {
    type: Array,
    required: true
  }
})
</script>

<template>
  <div class="mt-2 px-2 flex-grow overflow-auto space-y-3">
    <ul class="px-2 space-y-1" role="list">
      <li v-for="item in props.navigation" :key="item.name">
        <a v-if="!item.children"
           :class="[item.current ? 'bg-gray-200 font-semibold' : 'hover:bg-gray-200', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-normal text-gray-700']"
           :href="item.href">
            <template v-if="!!item.iconElem">
                <component :is="item.iconElem" class="size-4 shrink-0 text-gray-400"/>
            </template>
            <template v-else>
                <CIcon :icon="item.icon" aria-hidden="true" class="size-4 shrink-0 text-gray-400"/>
            </template>
          {{ item.name }}
        </a>
        <Disclosure v-else v-slot="{ open }" as="div">
          <DisclosureButton
              :class="[item.current ? 'bg-gray-200 font-semibold' : 'hover:bg-gray-200', 'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-normal text-gray-700']">
              <template v-if="!!item.iconElem">
                  <component :is="item.iconElem" class="size-4 shrink-0 text-gray-400"/>
              </template>
              <template v-else>
            <CIcon :icon="item.icon" aria-hidden="true" class="size-4 shrink-0 text-gray-400"/>
                  </template>
            {{ item.name }}
            <ChevronRightIcon
                :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                aria-hidden="true"/>
          </DisclosureButton>
          <DisclosurePanel as="ul" class="mt-1 px-2">
            <li v-for="subItem in item.children" :key="subItem.name">
              <template v-if="subItem.children">
                <Disclosure v-slot="{ open }" as="div">
                  <DisclosureButton
                      :class="[subItem.current ? 'bg-gray-200 font-semibold' : 'hover:bg-gray-200', 'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 text-gray-700']">
                    <CIcon :icon="subItem.icon" aria-hidden="true" class="size-4 shrink-0 text-gray-400"/>
                    {{ subItem.name }}
                    <ChevronRightIcon
                        :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                        aria-hidden="true"/>
                  </DisclosureButton>

                  <DisclosurePanel as="ul" class="mt-1 px-2">
                    <li v-for="subSubItem in subItem.children" :key="subSubItem.name">
                      <DisclosureButton
                          :class="['hover:bg-gray-200', 'block rounded-md py-2 pl-9 pr-2 text-sm/6 text-gray-700']"
                          :href="subSubItem.href"
                          as="a">
                        {{ subSubItem.name }}
                      </DisclosureButton>
                    </li>
                  </DisclosurePanel>
                </Disclosure>
              </template>
              <template v-else>
                <DisclosureButton
                    :class="['hover:bg-gray-200', 'block rounded-md py-2 pl-9 pr-2 text-sm/6 text-gray-700']"
                    :href="subItem.href"
                    as="a">
                  {{ subItem.name }}
                </DisclosureButton>
              </template>
            </li>
          </DisclosurePanel>
        </Disclosure>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>