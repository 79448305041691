<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, ref} from "vue";

const props = defineProps({
    class: {type: [String, Object, null], required: false, default: null},
    labelClass: {type: [String, Object, null], required: false, default: null},
    inputClass: {type: [String, Object, null], required: false, default: null},
    name: {type: String, required: false, default: `field-${generateUuidString()}`},
    label: {type: [String], required: false, default: '입력'},
    placeholder: {type: [String, null], default: null},
    type: {type: String, required: false, default: 'text'},
    required: {type: Boolean, required: false, default: false},
    errors: {type: Array, required: false, default: []},
    messages: {type: Array, required: false, default: []},
    autoSelect: {type: Boolean, required: false, default: false},
    readonly: {type: Boolean, required: false, default: false},
    displayFunction: {type: Function, required: false, default: (value) => value},
    value: {type: [String, Number, null], required: false, default: null},
})

const emits = defineEmits(['focus', 'blur', 'enter', 'input', 'change'])

const model = defineModel()

const focused = ref(false)
const inputId = `id_for_${props.name}`
const placeholder = computed(() => {
    return props.placeholder ? props.placeholder : props.label
})

const invalid = computed(() => {
    return props.errors.length > 0
})

const labelClass = computed(() => {
    if (props.readonly === true) {
        return ['text-gray-700', 'font-medium', 'z-20']
    }
    let validClass = invalid.value ? ['text-red-600'] : ['text-gray-700']
    let focusedClass = focused.value ? ['font-semibold', 'text-primary-600'] : ['font-medium']
    return [
        props.labelClass,
        ...validClass,
        ...focusedClass,
        'z-20'
    ]
})

const inputClass = computed(() => {
    let validClass = []
    if (props.readonly === true) {
        validClass = ['border-gray-300', 'bg-gray-900']
        if (focused.value) {
            validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
        }
        return validClass
    }
    if (invalid.value) {
        validClass = ['text-red-600', 'border-red-300',]
        if (focused.value) {
            validClass = [...validClass, 'ring-1', 'ring-red-600', 'border-red-600']
        }
    } else {
        validClass = ['text-gray-700', 'border-gray-300']
        if (focused.value) {
            validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
        }
    }
    return [props.inputClass, ...validClass]
})

const setInput = (event) => {
    model.value = event.target.value === '' ? null : event.target.value
    emits('input', event)
}

const focus = (event) => {
    focused.value = true
    if (props.autoSelect) {
        event.target.select()
    }
    emits('focus', event)
}
const blur = (event) => {
    focused.value = false
    emits('blur', event)
}

const enter = (event) => {
    emits('enter', enter)
}

const change = (event) => {
    if (props.readonly) {
        event.preventDefault()
    }
    emits('change', change)
}

const displayValue = computed(() => {
    if (props.value === null) {
        try {
            return props.displayFunction(model.value)
        } catch (e) {
            return model.value
        }
    } else {
        return props.value
    }
})

</script>

<template>
    <div :class="props.class" class="relative">
        <label :class="[...labelClass, props.readonly ? 'readonly':'']" :for="inputId">
            {{ props.label }} <span v-if="props.required" class="text-red-600">*</span>
        </label>

        <div :class="[...inputClass, props.readonly ? 'readonly':'']" class="input-wrapper">
            <input :id="inputId" :name="props.name" :placeholder="placeholder" :readonly="readonly"
                   :type="props.type" :value="displayValue"
                   @blur="blur" @change="change" @focus="focus" @input="setInput" @keydown.enter="enter"/>
            <slot name="button"/>
        </div>
        <template v-if="invalid">
            <div class="message-wrapper error">
                <p v-for="error in props.errors">- {{ error }}</p>
            </div>
        </template>
        <template v-else-if="props.messages.length > 0">
            <div class="message-wrapper">
                <p v-for="message in props.messages">- {{ message }}</p>
            </div>
        </template>
    </div>
</template>

<style scoped>
label {
    @apply absolute text-xs -top-2 left-2 inline-block bg-white px-1 py-0
}

label.readonly {
    @apply bg-transparent
}

.input-wrapper {
    @apply flex w-full leading-5 px-2 py-0 bg-white rounded h-[2rem] border-[1px] gap-x-2 items-center
}

.input-wrapper.readonly {
    @apply bg-gray-300
}

input {
    all: unset;
    @apply focus:ring-0 flex-grow min-w-0 text-sm
}

input::placeholder {
    @apply text-gray-400/70
}

input:read-only {
    @apply bg-gray-300
}


.message-wrapper {
    @apply mt-[2px] text-xs text-gray-500
}

.message-wrapper.error {
    @apply text-red-500
}
</style>