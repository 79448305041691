import {createRouter, createWebHistory} from "vue-router";
import {useAuthStore} from "@/stores/authStore";
import NoNavLayout from "@/layout/NoNavLayout.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import TestPage from "@/views/settings/TestPage.vue";
import {useWindowSize} from "@vueuse/core";

export const RoutesData = {
    // routes wide하게 name과 path가 중복되면 안됨!
    Auth: {
        path: '/auth', component: NoNavLayout,
        children: {
            Login: {
                path: '/auth/login', name: 'login', component: () => import("@/views/auth/LoginPage.vue"), meta: {authRequired: false}
            },
            Logout: {
                path: '/auth/logout', name: 'logout', component: () => import("@/views/auth/LogoutPage.vue"), meta: {authRequired: true}
            },
            PasswordReset: {
                path: '/auth/password-reset', name: 'password-reset', component: () => import("@/views/auth/PasswordResetPage.vue"), meta: {authRequired: false}
            }
        }
    },
    Main: {
        path: '/',
        children: {
            Home: {
                path: '/', name: 'home', component: () => import("@/views/dashboard/Dashboard.vue"), meta: {authRequired: true}
            },
            Error: {
                path: '/error', name: 'error', component: () => import("@/views/errors/ErrorView.vue"), meta: {authRequired: null}
            },
        }
    },
    Personal: {
        path: '/personal',
        name: 'profile', component: () => import("@/views/profile/ProfilePage.vue"), meta: {authRequired: true}
    },
    SmallWork: {
        path: '/work/small',
        children: {
            List: {
                path: '/work/small', name: 'work-small',
                children: {
                    List: {path: '/work/small', name: 'work-small-list', component: () => import("@/views/work/SmallWorkListPage.vue"), meta: {authRequired: true}},
                }
            }
        }
    },
    UnusedWork: {
        path: '/work/unused',
        children: {
            Campaign: {
                path: '/work/unused/campaign', name: 'work-unused-campaign-list', component: () => import("@/views/work/UnusedCampaignListPage.vue"), meta: {authRequired: true}
            },
            Site: {
                path: '/work/unused/site', name: 'work-unused-site-list', component: () => import("@/views/work/UnusedWorkSiteListPage.vue"), meta: {authRequired: true}
            },
            Scrap: {
                path: '/work/unused', name: 'work-unused-work-list', component: () => import("@/views/work/UnusedWorkListPage.vue"), meta: {authRequired: true}
            }
        }
    },
    DeliveryWork: {
        path: '/work/delivery',
        children: {
            SiteList: {
                path: '/work/delivery/site', name: 'work-delivery-site-list', component: () => import("@/views/work/delivery/DeliverySiteListPage.vue"), meta: {authRequired: true}
            },
            Request: {
                path: '/work/delivery/request', name: 'work-delivery-request-list', component: () => import("@/views/work/delivery/DeliveryWorkRequestListPage.vue"), meta: {authRequired: true}
            },
            Cost: {
                path: '/work/delivery/cost', name: 'work-delivery-cost-list', component: () => import("@/views/work/delivery/DeliveryTransportCostListPage.vue"), meta: {authRequired: true}
            },
            // Site: {
            //     path: '/work/unused/site', name: 'work-unused-site-list', component: () => import("@/views/work/UnusedWorkSiteListPage.vue"), meta: {authRequired: true}
            // },
            // Scrap: {
            //     path: '/work/unused', name: 'work-unused-work-list', component: () => import("@/views/work/UnusedWorkListPage.vue"), meta: {authRequired: true}
            // }
        }
    },
    Work: {
        path: '/work',
        children: {
            Small: {
                path: '/work/small', name: 'work-small',
                children: {
                    List: {path: '/work/small', name: 'work-small-list', component: () => import("@/views/work/SmallWorkListPage.vue"), meta: {authRequired: true}},
                }
            }
        }
    },
    Sales: {
        path: '/sales',
        children: {
            Sales: {
                path: '/sales/sales', name: 'sales', component: () => import("@/views/sales/sales/SalesListPage.vue"), meta: {
                    authRequired: true
                }
            },
            Customer: {
                path: '/sales/customer', name: 'contact-customer-list', component: () => import("@/views/sales/customer/CustomerListPage.vue"), meta: {
                    authRequired: true
                }
            },
        }
    },
    Affiliate: {
        path: '/affiliate',
        children: {
            Affiliate: {
                path: '/affiliate/affiliate', name: 'affiliate-affiliate-list', component: () => import("@/views/sales/affiliate/AffiliateListPage.vue"), meta: {
                    authRequired: true
                }
            },
            AffiliatePrice: {
                path: '/affiliate/affiliate/price', name: 'affiliate-affiliate-price', component: () => import("@/views/sales/affiliate/AffiliatePricePage.vue"), meta: {
                    authRequired: true
                }
            }
        }
    },
    Accounting: {
        path: '/accounting',
        children: {
            Code: {
                path: '/accounting/code', name: 'accounting-code', component: () => import("@/views/accounting/EntryCodeListPage.vue"), meta: {authRequired: true}
            },
            Bill: {
                path: '/accounting/bill', name: 'accounting-bill', component: () => import("@/views/accounting/BillListPage.vue"), meta: {authRequired: true}
            },
            EntryUnbilled: {
                path: '/accounting/entry/unbiled', name: 'accounting-entry-unbiled', component: () => import("@/views/accounting/EntryListUnBilledPage.vue"), meta: {authRequired: true}
            },
        }
    },
    CallLog: {
        path: '/call-log',
        children: {
            CallLog: {
                path: '/call-log/log', name: 'call-log-log', component: () => import("@/views/call_log/CallLogList.vue"), meta: {authRequired: true}
            },
            Device: {
                path: '/call-log/device', name: 'call-log-device', component: () => import("@/views/call_log/DeviceList.vue"), meta: {authRequired: true}
            }
        }
    },
    Vehicle:{
        path: '/vehicle',
        children: {
            List: {
                path: '/vehicle', name: 'vehicle-list', component: () => import("@/views/vehicle/VehicleListPage.vue"), meta: {authRequired: true}
            }
        }

    },
    Settings: {
        path: '/settings',
        children: {
            Employee: {
                path: '/settings/employee', name: 'settings-employee', component: () => import("@/views/settings/EmployeeListPage.vue"), meta: {authRequired: true}
            },
            EmployeeDetail: {
                path: '/settings/employee/:id', name: 'settings-employee-detail', component: () => import("@/views/settings/EmployeeDetailPage.vue"), meta: {authRequired: true}
            },
            Company: {
                path: '/settings/company', name: 'settings-company', component: () => import("@/views/settings/CompanyListPage.vue"), meta: {authRequired: true}
            },
            CompanyDetail: {
                path: '/settings/company/:id', name: 'settings-company-detail', component: () => import("@/views/settings/CompanyDetailPage.vue"), meta: {authRequired: true}
            },
            Campaign: {
                path: '/settings/campaign', name: 'settings-campaign-list', component: () => import("@/views/sales/campaign/CampaignListPage.vue"), meta: {
                    authRequired: true
                }
            },
            Test: {
                path: '/settings/test', name: 'testview', component: TestPage, meta: {authRequired: true}
            }
        }
    },
}

const unpackRoute = (route) => {
    let routerData = {}
    routerData.path = route.path
    routerData.component = route.component
    if (route.meta) {
        routerData.meta = route.meta
    }
    if (!route.children) {
        routerData.name = route.name
    } else {
        let children = []
        Object.entries(route.children).forEach(([key, value]) => {
            children.push(unpackRoute(value))
        })
        routerData.children = children
    }
    return routerData
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: Object.entries(RoutesData).map(([key, value]) => unpackRoute(value))
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const layoutStore = useLayoutStore()
    const {width: windowWidth} = useWindowSize()
    // 보여지고 있는 모달을 닫는다.
    layoutStore.confirm = null
    layoutStore.alert = null
    layoutStore.notifications = []
    layoutStore.mobileNav = windowWidth.value >= 768
    let authRequired = [true, false, null].indexOf(to.meta?.authRequired) > -1 ? to.meta.authRequired : null;
    if (authRequired === true) {
        if (authStore.access == null) {
            authStore.user = null;
            authStore.access = null;
            authStore.refresh = null;
            layoutStore.addErrorNotification('로그인 필요', '로그인이 필요한 서비스 입니다.', 2000)
            next(RoutesData.Auth.children.Login.path)
        } else {
            await authStore.verifyToken()
            next()
        }
    } else if (authRequired === false) {
        if (authStore.access === null) {
            next()
        } else {
            layoutStore.addInfoNotification('로그인 확인', '이미 로그인 되었습니다.', 2000)
            next('/')
        }
    } else {
        next();
    }
});

router.onError(async (error) => {
    console.log(error)
    alert('error')
    // if (error.nextRoute) {
    //     await router.push(error.nextRoute);
    // } else {
    //     await router.push("/");
    // }
});

export default router;
