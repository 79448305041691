<script setup>

import {Sizes} from "@/constant.js"
import {Dialog, DialogPanel, DialogTitle, TransitionRoot} from "@headlessui/vue";
import GlobalOverlayBase from "@/components/commons/GlobalOverlayBase.vue";
import {computed, ref} from "vue";
import {XMarkIcon} from "@heroicons/vue/24/solid";
import {useDraggable, useElementBounding} from "@vueuse/core";

const props = defineProps({
  isOpen: {type: Boolean, required: true},
  title: {type: String, required: true},
  confirmButtonText: {type: String, required: false, default: '확인'},
  cancelButtonText: {type: String, required: false, default: '취소'},
  size: {type: String, required: false, default: Sizes.MEDIUM},
    showClose: {type: Boolean, default: true},
    noFooter: {type: Boolean, default: false},
  callback: {
    type: Function, required: false, default: () => {
    }
  }
})

const emits = defineEmits(['close'])

const callback = async () => {
  await props.callback()
}

const close = () => {
  emits('close')
}

const sizeClass = computed(() => {
  switch (props.size) {
    case Sizes.SMALL:
      return 'w-[30rem]'
    case Sizes.MEDIUM:
      return 'w-[50rem]'
    case Sizes.LARGE:
      return 'w-[70rem]'
    default:
      return 'w-[50rem]'
  }
})

// const dialog = ref(null)
// const dialogTitle = ref(null)
// const {x: dialogX, y: dialogY, top, right, bottom, left, width, height} = useElementBounding(dialog)
// const {x, y, style} = useDraggable(dialogTitle, {
//   initialValue: {x: dialogX, y: dialogY}, immediate: false
// })


</script>

<template>
  <TransitionRoot
      :show="props.isOpen"
      as="template"
      enter="duration-300 ease-out"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="duration-200 ease-in"
      leave-from="opacity-100"
      leave-to="opacity-0"
  >
    <Dialog :open="props.isOpen" @close="close">
      <div ref="dialog" class="fixed inset-0 flex items-center justify-center bg-gray-900/30 z-50">
        <DialogPanel :class="sizeClass" class="flex flex-col bg-white shadow-xl max-h-[80%]">
          <slot name="title">
            <DialogTitle as="template">
              <div
                  ref="dialogTitle"
                  class="bg-primary-500 px-4 py-2 text-sm font-semibold text-white flex items-center justify-between select-none">
                <div>{{ props.title }}</div>
                <button @click="close" v-if="props.showClose">
                  <XMarkIcon class="text-white size-4"/>
                </button>
              </div>
            </DialogTitle>
          </slot>
          <div class="overflow-auto flex flex-row p-0 w-full">
            <slot/>
          </div>
            <slot name="footer" v-if="!props.noFooter">
          <div class="flex justify-end p-4 border-t">
              <button id="cancel-button" class="btn btn-secondary mr-2" @click="close">{{
                  props.cancelButtonText
                }}
              </button>
              <button id="confirm-button" class="btn btn-primary" @click="callback">{{
                  props.confirmButtonText
                }}
              </button>
          </div>
            </slot>
        </DialogPanel>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>

</style>