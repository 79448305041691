import {createApp} from "vue";
import {createPinia} from "pinia";


import "@/assets/style.css"

import App from "./App.vue";
import router from "./router";
import {APIError, BaseError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {
    cilTruck,
    cilChart,
    cilPhone,
    cilGarage,
    cilCog,
    cilAssistiveListeningSystem,
    cilDollar,
    cilFactory,
    cilLeaf,
    cilInfo
} from '@coreui/icons';
import axios from "axios";
import {setupCalendar} from 'v-calendar';
import 'v-calendar/style.css';


import * as Sentry from "@sentry/vue";
import {APP_STAGE, API_URL} from "@/service/api.js";

export const app = createApp(App);

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Do something ...
    const responseData = error.response.data
    const err = new APIError(
        responseData.code, responseData.message, responseData.field_errors, responseData.non_field_errors, error
    )

    const layoutStore = useLayoutStore()
    let detail = []
    if (err.message) {
        detail.push(err.message)
    }
    if (err.non_field_errors) {
        detail = [...detail, ...err.non_field_errors]
    }
    // layoutStore.addErrorNotification(`오류 : ${err.code}`, detail, 5000)
    return Promise.reject(err);
});

app.config.errorHandler = async (err, vm, info) => {
    //https://enterprisevue.dev/blog/error-handling-in-vue-3/
    const layoutStore = useLayoutStore()
    layoutStore.hideOverlay()
    if (err instanceof BaseError || err instanceof APIError) {
        // BaseError의 Subclass를 모두 포함
        // 대부분의 APIError는 400 과 함께 field_errors가 내려오기 때문에
        // 여기(global handler)에서 처리하지 않고 view/component에서 catch해서 처리하고
        // 가급적이면 여기로 보내지 마세요.
        const layoutStore = useLayoutStore()
        let detail = [...err.non_field_errors]
        if (err.message) {
            detail.push(err.message)
        }
        layoutStore.addErrorNotification(`오류 : ${err.code}`, detail, 5000)
    } else {
        layoutStore.addErrorNotification('알 수 없는 오류', err.message, 5000)
    }
}

 window.onerror = function (msg, url, line, col, err) {
     //code to handle or report error goes here
    const layoutStore = useLayoutStore()
    layoutStore.hideOverlay()
    if (err instanceof BaseError || err instanceof APIError) {
        // BaseError의 Subclass를 모두 포함
        // 대부분의 APIError는 400 과 함께 field_errors가 내려오기 때문에
        // 여기(global handler)에서 처리하지 않고 view/component에서 catch해서 처리하고
        // 가급적이면 여기로 보내지 마세요.
        const layoutStore = useLayoutStore()
        let detail = [...err.non_field_errors]
        if (err.message) {
            detail.push(err.message)
        }
        layoutStore.addErrorNotification(`오류 : ${err.code}`, detail, 5000)
    } else {
        layoutStore.addErrorNotification('알 수 없는 오류', msg, 5000)
    }
 }
const icons = {
    cilTruck: cilTruck,
    cilChart: cilChart,
    cilPhone: cilPhone,
    cilGarage: cilGarage,
    cilCog: cilCog,
    cilFactory: cilFactory,
    cilDollar: cilDollar,
    cilAssistiveListeningSystem: cilAssistiveListeningSystem,
    cilLeaf: cilLeaf,
    cilInfo
}
app.provide('icons', icons)

if (APP_STAGE === 'PRODUCTION') {
    Sentry.init({
        app,
        dsn: "https://b4ee2106994a2ec572dcfa3f65fc2474@o4504468774715392.ingest.us.sentry.io/4508086198403072",
        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

app.use(createPinia());
app.use(router);

// Use plugin with optional defaults
app.use(setupCalendar, {})
app.mount("#app");
